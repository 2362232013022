import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import Image from "./image"
import avatar from "../images/IMG_4681.png"
import "./sidebar.css"

const Sidebar = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        file(base: { eq: "linkedin-social-icon-color.png" }) {
          publicURL
        }
        linkedInSocialIcon: file(base: { eq: "linkedin-social-icon.png" }) {
          publicURL
        }
      }
    `}
    render={data => (
      <div className="sidebar-wrapper">
        <div className="about-container">
          <img src={avatar} className="avatar" />
          <h1>DVELOPER. FOUNDER.</h1>
          <p>
            Currently developing @Roomovo and @TeamMeister. Formerly Experience
            Lead Frontend for a Horistic Charging Infrastructure and one of the
            first Developer of TeleClinic (E-Health StartUp).
          </p>
          <div className="social">
            <a
              href="https://www.linkedin.com/in/dominik-seibold/"
              target="_blank"
            >
              <div
                href="#"
                css={css`
                  width: 30px;
                  height: 30px;
                  background: url(${data.linkedInSocialIcon.publicURL})
                    center/cover no-repeat;
                  &:hover {
                    background: url(${data.file.publicURL}) center/cover
                      no-repeat;
                  }
                `}
              />
            </a>
          </div>
        </div>
      </div>
    )}
  />
)

export default Sidebar
